export const useOnboardStore = defineStore("onboardStore", () => {
    // State
    const clientState = reactive({
        first_name: null,
        preferred_name: null,
        middle_name: null,
        last_name: null,
        date_of_birth: null,
        gender: null,
        marital_status: null,
        email: null,
    });
    const insuranceCompaniesMap = ref(new Map());
    const partner = ref(null);

    // Getters
    const insuranceCompanies = computed(() => {
        return [...insuranceCompaniesMap.value.values()];
    });
    const insuranceCompanyById = computed(() => {
        return (id: string) => insuranceCompaniesMap.value.get(id);
    });
    const insurancePolicy = computed(() => {
        const accountStore = useAccountStore();
        const { insurance } = storeToRefs(accountStore);
        return insurance.value?.length ? insurance.value[0] : null;
    });

    const isInsuranceStepComplete = computed(() => {
        return insurancePolicy.value ? true : false;
    });
    const isClientStepComplete = computed(() => {
        const accountStore = useAccountStore();
        const { account } = storeToRefs(accountStore);
        return account.value?.clients.length ? true : false;
    });
    const isPlanStepComplete = computed(() => {
        return insurancePolicy.value?.plan ? true : false;
    });
    const isBillingStepComplete = computed(() => {
        const accountStore = useAccountStore();
        const { account } = storeToRefs(accountStore);
        return account.value?.stripe_payment_method_id ? true : false;
    });
    const isCardUploadStepComplete = computed(() => {
        return insurancePolicy.value?.policy_document_id_front ||
            insurancePolicy.value?.policy_document_id_back
            ? true
            : false;
    });

    const onboardStatus = computed(() => {
        let onboardStatus = "incomplete";
        if (onboardingComplete.value) {
            onboardStatus = "complete";
            if (
                insurancePolicy.value?.insurance_company_id &&
                insurancePolicy.value?.plan
            ) {
                onboardStatus = "approved";
            } else onboardStatus = "needs_approval";
        } else onboardStatus = "incomplete";
        return onboardStatus;
    });

    const onboardingProgress = computed(() => [
        {
            label: "Insurance policy",
            description: "Add your insurance policy",
            complete: isInsuranceStepComplete.value,
            path: "/onboard/insurance",
            icon: "i-heroicons-shield-check-solid",
            buttonLabel: "Get started",
            disabled: false,
        },
        {
            label: "Client information",
            description: "Add a client to your account",
            complete: isClientStepComplete.value,
            path: "/onboard/clients",
            icon: "i-heroicons-user-solid",
            buttonLabel: "Add",
            disabled: isInsuranceStepComplete.value ? false : true,
        },
        {
            label: "Insurance cards",
            description: "Upload your insurance card",
            complete: isCardUploadStepComplete.value,
            path: "/onboard/card-upload",
            icon: "i-heroicons-identification-solid",
            buttonLabel: "Upload",
            disabled: isInsuranceStepComplete.value ? false : true,
        },
        {
            label: "Payment plan",
            description: "Choose a payment plan",
            complete: isPlanStepComplete.value,
            path: "/onboard/eligibility",
            icon: "i-heroicons-bookmark-solid",
            buttonLabel: "Select",
            disabled: isInsuranceStepComplete.value ? false : true,
        },
        {
            label: "Payment method",
            description: "Add a payment method",
            complete: isBillingStepComplete.value,
            path: "/onboard/billing",
            icon: "i-heroicons-credit-card-solid",
            buttonLabel: "Add",
            disabled: isInsuranceStepComplete.value ? false : true,
        },
    ]);
    const onboardingComplete = computed(() => {
        const stepIsComplete = (step: any) => step.complete === true;
        return onboardingProgress.value.every(stepIsComplete);
    });

    const client = computed(() => {
        const accountStore = useAccountStore();
        const { accountClients } = storeToRefs(accountStore);
        return accountClients.value.length ? accountClients.value[0] : null;
    });

    // Actions
    async function fetchInsuranceCompanies(reload?: boolean) {
        console.log("fetchInsuranceCompanies");
        const userStore = useUserStore();
        const { accountId } = storeToRefs(accountStore);

        if (insuranceCompaniesMap.value.has(id) && !reload) return;
        const data: any = await $fetchWithToken(
            `/api/v4/account/insurance-companies`
        );
        console.log("insurance-companies data: ", data);
        insuranceCompaniesMap.value.clear();
        data.forEach((company: any) => {
            insuranceCompaniesMap.value.set(company.id, company);
        });
    }

    async function fetchPartnerBySlug(slug: string) {
        console.log("fetchPartnerBySlug", slug);

        const { data, error } = await useFetch(
            `/api/v4/onboard/partners/slug/${slug}`
        );
        console.log("partner data: ", data.value);
        partner.value = data.value;
    }

    async function goToNextOnboardingStep() {
        console.log("goToNextOnboardingStep");
        const route = useRoute();

        const checkIfPreviousStep = (nextPath: string) => {
            const currentStepIndex = onboardingProgress.value.findIndex(
                (step) => step.path == route.path
            );
            const nextStepIndex = onboardingProgress.value.findIndex(
                (step) => step.path == nextPath
            );
            return nextStepIndex > currentStepIndex ? false : true;
        };

        for (const step of onboardingProgress.value) {
            let skipThisStep = checkIfPreviousStep(step.path);
            if (!step.complete) {
                if (skipThisStep) continue;
                return await navigateTo({ path: step.path });
            }
        }
        // All steps are complete, go to confirmation page
        await navigateTo({ path: "/onboard" });
        window.scrollTo(0, 0);
    }

    async function fetchInsuranceCompanyById(id: string) {
        console.log("fetchInsuranceCompanyById id: ", id);

        // const accountStore = useAccountStore();
        // const accountId = userStore.accountId;
        // // console.log("fetchAccount accountId: ", userStore.accountId);

        if (insuranceCompaniesMap.value.size) return;
        const data: any = await $fetchWithToken(
            `/api/v4/account/insurance-companies/${id}`
        );
        console.log("insurance-companies data: ", data);
        insuranceCompaniesMap.value.set(data.id, data);
    }

    function $reset() {
        insuranceCompaniesMap.value.clear();
    }

    return {
        clientState,
        partner,

        insuranceCompanies,
        insuranceCompanyById,
        insurancePolicy,
        client,
        onboardingProgress,
        onboardingComplete,
        onboardStatus,

        goToNextOnboardingStep,
        fetchInsuranceCompanies,
        fetchInsuranceCompanyById,
        fetchPartnerBySlug,
        $reset,
    };
});
